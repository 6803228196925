body {
  margin: 0;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.post-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 16px;
  padding: 16px;
  align-items: stretch;
}

/* Ensure posts take full width if the screen is narrower than 500px */
@media (max-width: 576px) {
  .post-container {
    grid-template-columns: 1fr; /* Force a single column */
  }
}

.post-card-div {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.post-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.post-content {
  max-height: 600px; /* Limit the height */
  height: 100%;
  overflow: hidden;
  position: relative;
}

.post-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4%; /* Fading effect height */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--bs-card-bg));
}

.no-bottom-margin p {
  margin-bottom: 0;
}

.leaflet-container{
  width:100%;
  height: 40vh;
}

.loginCard {

  -webkit-box-shadow: 0 10px 30px 0 rgba(71, 98, 71, 0.43);
  -moz-box-shadow: 0 10px 30px 0 rgba(58, 80, 58, 0.43);
  box-shadow: 0 10px 30px 0 rgba(44, 69, 44, 0.43);
  max-width: 650px;
  margin: auto;
  padding: 1%;

}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) translateX(3%);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.8) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.pulse {
  animation: pulse 1.5s infinite; /* Adjust the duration as needed */
}

.spin-pulse {
  animation: spin 1.5s infinite;
}

.falling-leaves-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaf {
  position: absolute;
  font-size: 2em; /* Adjust size of the leaf icon */
  color: brown; /* Default brown color for leaves */
  opacity: 0.8;
  top: -50px;
}

.leaf:nth-child(odd) {
  color: #A0522D; /* sienna brown */
}

.leaf:nth-child(even) {
  color: #CD853F; /* peru brown */
}

.leaf.center {
  animation: wind 4s infinite linear;
}

.leaf.upper {
  animation: wind-upper 4s infinite linear;
}

.leaf.lower {
  animation: wind-lower 4s infinite linear;
}

@keyframes wind {
  0% {
    transform: translateX(-50vw) translateY(50vh) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translateX(-25vw) translateY(50vh) rotate(180deg);
    opacity: 0.9;
  }
  50% {
    transform: translateX(0vw) translateY(50vh) rotate(360deg);
    opacity: 0.7;
  }
  75% {
    transform: translateX(25vw) translateY(50vh) rotate(540deg);
    opacity: 0.6;
  }
  100% {
    transform: translateX(50vw) translateY(50vh) rotate(720deg);
    opacity: 0.5;
  }
}

@keyframes wind-upper {
  0% {
    transform: translateX(-50vw) translateY(45vh) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translateX(-25vw) translateY(45vh) rotate(300deg);
    opacity: 0.9;
  }
  50% {
    transform: translateX(0vw) translateY(45vh) rotate(600deg);
    opacity: 0.7;
  }
  75% {
    transform: translateX(25vw) translateY(45vh) rotate(900deg);
    opacity: 0.6;
  }
  100% {
    transform: translateX(50vw) translateY(45vh) rotate(1200deg);
    opacity: 0.5;
  }
}

@keyframes wind-lower {
  0% {
    transform: translateX(-50vw) translateY(55vh) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: translateX(-25vw) translateY(55vh) rotate(-200deg);
    opacity: 0.9;
  }
  50% {
    transform: translateX(0vw) translateY(55vh) rotate(-400deg);
    opacity: 0.7;
  }
  75% {
    transform: translateX(25vw) translateY(55vh) rotate(-600deg);
    opacity: 0.6;
  }
  100% {
    transform: translateX(50vw) translateY(55vh) rotate(-800deg);
    opacity: 0.5;
  }
}

/* Different animation delays for variety */
.leaf:nth-child(1) { animation-delay: 0s; }
.leaf:nth-child(2) { animation-delay: -0.5s; }
.leaf:nth-child(3) { animation-delay: -1s; }
.leaf:nth-child(4) { animation-delay: -1.5s; }
.leaf:nth-child(5) { animation-delay: -2s; }
.leaf:nth-child(6) { animation-delay: -2.5s; }
.leaf:nth-child(7) { animation-delay: -3s; }
.leaf:nth-child(8) { animation-delay: -3.5s; }
.leaf:nth-child(9) { animation-delay: -4s; }
.leaf:nth-child(10) { animation-delay: 0s; }
.leaf:nth-child(11) { animation-delay: -0.5s; }
.leaf:nth-child(12) { animation-delay: -1.5s; }
.leaf:nth-child(13) { animation-delay: -2s; }
.leaf:nth-child(14) { animation-delay: -2.5s; }
.leaf:nth-child(15) { animation-delay: -3.5s; }

/* Container covers full viewport so the snow can fall from top to bottom */
.winter-snow-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* All snow items share these base styles */
.snow {
  position: absolute;
  top: -3em; /* Start slightly above the visible area */
  font-size: 2em;
  color: #88d8fb;
  opacity: 1;
  animation: fallingSnow 4s linear infinite;
}

/*
  Keyframes for snow falling:
  - Move from top (just above screen) to below screen
  - Fade out as it falls
*/
@keyframes fallingSnow {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    transform: translateY(120vh);
    opacity: 0;
  }
}
